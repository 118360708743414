// import MapComponent from "../components/Map";
import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import OfficeSide from "../app/assets/img/bta-office-side.png";
import VerticalTable from "../app/assets/img/massage -table-vertical.png";
import Office from "../app/assets/img/office.png";
import HorizontalTable from "../app/assets/img/massage-table-side.png";
import Lobby from "../app/assets/img/bta-lobby.jpg";
import Space from "../app/assets/img/bta-office-photo.jpeg";

const SuitePage = () => {
  const items = [
    { src: OfficeSide, altText: "Office Side", caption: "" },
    { src: VerticalTable, altText: "Vertical Table", caption: "" },
    { src: Office, altText: "Office", caption: "" },
    { src: HorizontalTable, altText: "Horizontal Table", caption: "" },
    { src: Lobby, altText: "Lobby", caption: "" },
    { src: Space, altText: "Office Space", caption: "" },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} className="suite-img" />
        <CarouselCaption captionText={item.caption} />
      </CarouselItem>
    );
  });

  return (
    <>
      <Row className="intro-text">
        <Col>
          <h2>
            Welcome to Body Therapy Arts in Encinitas… Your Neighborhood
            Resource for Wellness!
          </h2>
          <p>
            We’re conveniently located in the heart of Village Park in beautiful
            Encinitas, providing integrative healthcare services to the
            surrounding communities of Encinitas, Cardiff by the Sea, Leucadia,
            Carlsbad, Solana Beach, Rancho Santa Fe, Del Mar and all of North
            San Diego County since 1998.
          </p>
        </Col>
      </Row>
      <Container className="suite-img-container">
        <Row className="justify-content-center">
          <Col className="mx-auto">
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {slides}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
              />
            </Carousel>
            <div className="thumbnails-container mt-3">
              <Row className="justify-content-center">
                {items.map((item, index) => (
                  <Col
                    xs="4"
                    sm="2"
                    key={index}
                    className="d-flex justify-content-center mb-4"
                  >
                    <img
                      src={item.src}
                      alt={item.altText}
                      className="suite-thumbnail"
                      onClick={() => goToIndex(index)}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </Col>
          {/* <Col className="suite-map-component col-12 col-lg-6">
            <MapComponent />
          </Col> */}
        </Row>
      </Container>
    </>
  );
};

export default SuitePage;
