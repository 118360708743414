import { SERVICES } from "./SERVICES.js";
import { Col, Row, Container, CardGroup } from "reactstrap";
import ServiceCard from "./ServiceCard.jsx";

const ServicesList = () => {
  return (
    <Row className="services-list ms-auto">
      {SERVICES.map((service) => {
        return (
          <Row key={service.id}>
            <CardGroup>
              <ServiceCard service={service} />
            </CardGroup>
          </Row>
        );
      })}
    </Row>
  );
};

export default ServicesList;
