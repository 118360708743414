import React, { useState } from "react";
import SubHeader from "../components/SubHeader.jsx";
import {
  Row,
  Container,
  Col,
  Card,
  CardBody,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import ServicesList from "../app/shared/features/services/ServicesList.jsx";
import { SERVICES } from "../app/shared/features/services/SERVICES.js";

const ServicesPage = () => {
  const initialServices = SERVICES.slice(0, 6);

  return (
    <Row className="services-page">
      <Col></Col>
      <Col className="intro-text col col-8 mb-4">
        <h2>We are here to help YOU feel your best!</h2> As independent and
        caring practitioners, we offer Massage Therapy for pain relief and
        relaxation, Pregnancy Massage, Deep Tissue Massage, Structural
        Integration, Neuro-Muscular Therapy, Acupuncture, Fertility Acupuncture
        and Energy Healing in a peaceful and healing environment.
      </Col>
      <Col></Col>
      <div className="services-list">
        <ServicesList service={initialServices} />
      </div>
    </Row>
  );
};

export default ServicesPage;
