import { Card, CardImg, CardImgOverlay, CardTitle, Col, Row } from "reactstrap";

const practitionerCard = ({ practitioner }) => {
  const {
    image,
    name,
    specialty,
    contact: { email, phone },
  } = practitioner;
  return (
    <Card className="card-body">
      <div className="container-inset">
        <Row>
          <Col>
            <CardImg src={image} alt={name} className="people-img" />
            <CardImgOverlay>
              <CardTitle className="card-title">{name}</CardTitle>
            </CardImgOverlay>
          </Col>
          <Col>
            <div>Specialty: {specialty}</div>
            <br></br>
            <div>
              Contact Information:<br></br>
              {email}
              <br></br>
              {phone}
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};
export default practitionerCard;
