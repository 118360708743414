import {
  Card,
  CardBody,
  CardGroup,
  CardImg,
  CardImgOverlay,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

const ServiceCard = ({ service }) => {
  const { image, about, type } = service;
  return (
    <Container >

      <Row>
        <Col>
          <Card className="card-body">
            <CardBody className="container-inset">
              <CardTitle className="mb-4">
                <h2>{type}</h2>
              </CardTitle>
              <Row>
                <Col md="3">
                  <CardImg src={image} alt={type} className="people-img" />
                </Col>
                <Col md="9">
                  <CardSubtitle>{about}</CardSubtitle>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default ServiceCard;
