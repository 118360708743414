import { PRACTITIONERS } from "./PRACTITIONERS.js";
import { Col, Row } from "reactstrap";
import PractitionerCard from "./PractitionerCard.jsx";

const PractitionersList = () => {
  return (
    <Row className="ms-auto">
      {PRACTITIONERS.map((practitioner) => {
        return (
          <Col md="5" className="wrapper m-4" key={practitioner.id}>
            <PractitionerCard practitioner={practitioner} />
          </Col>
        );
      })}
    </Row>
  );
};

export default PractitionersList;
