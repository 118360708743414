import { useState, useEffect } from "react";
import {
  Navbar,
  NavbarBrand,
  Row,
  Col,
  Collapse,
  NavbarToggler,
  Nav,
  NavItem,
  Button,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import Handlee from "../app/assets/img/handlee-bta-logo.png";
import Laila from "../app/assets/img/laila-bta-logo.png";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHandlee, setIsHandlee] = useState(true); // State to toggle between Handlee and Laila
  const [showGiftCard, setShowGiftCard] = useState(true); // State to toggle the Gift Card link

  const toggleNavbar = () => setIsOpen(!isOpen);
  const toggleStyle = () => setIsHandlee(!isHandlee); // Function to toggle the style
  const toggleGiftCard = () => setShowGiftCard(!showGiftCard); // Function to toggle the Gift Card link

  // Add useEffect to handle window resize events
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992 && isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isOpen]);

  return (
    <>
      <Row className="header-color"></Row>
      <Navbar expand="lg" className=" col-10 site-header">
        <NavbarBrand href="/" className="navbarbrand">
          <Col>
            <img
              className=" col-11 banner"
              src={isHandlee ? Handlee : Laila}
              alt="Body Therapy Arts Holistic Associates"
            />
          </Col>
        </NavbarBrand>
        <Col className="col-6 col-xl-5">
          <NavbarToggler
            onClick={toggleNavbar}
            className={isOpen ? "navbar-toggler hidden" : "navbar-toggler"}
          />
          <Collapse
            isOpen={isOpen}
            navbar
            className={
              isHandlee
                ? "custom-navbar-collapse"
                : "laila-custom-navbar-collapse"
            }
          >
            <Nav className="dropdown-nav hstack pt-0" navbar>
              <NavItem>
                <NavLink to="/contact" target="_blank" rel="noreferrer">
                  <span className="contact-info-btn gift-card-container">
                    Contact
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className="the-space-btn contact-info-btn" to="/suite">
                  <span>The Space</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="services-btn contact-info-btn"
                  to="/services"
                >
                  <span>Services</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Col>
      </Navbar>
      <Row className="header-img"></Row>
    </>
  );
};

export default Header;
